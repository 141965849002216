export default {
  install (Vue) {
    // table scroll
    Vue.directive('loadmore', {
      bind (el, binding) {
        // console.log('777', el, binding)
        const selectWrap = el.querySelector('.el-table__body-wrapper')
        selectWrap.addEventListener('scroll', () => {
          const scrollDistance = selectWrap.scrollHeight - selectWrap.scrollTop - selectWrap.clientHeight
          if (scrollDistance <= 1) {
            binding.value()
          }
        })
      }
    })
  }
}

