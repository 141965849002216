import Vue from 'vue'
import Router from 'vue-router'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueCookies from 'vue-cookies'
import CryptoJS from 'crypto-js'
import router from './router'
import preventReClick from './libs/PreventRepeatClick'
import loadmore from './libs/tableScroll'
import vueSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import './assets/style/global.css'
import $message from './libs/messageCom'
import { hashMap } from '@/libs/utils/index';
// import x2js from 'x2js'

ElementUI.Dialog.props.lockScroll.default = false
Vue.use(Router)

// 保存用户名密码
Vue.use(VueCookies)
Vue.prototype.CryptoJS = CryptoJS
Vue.use(ElementUI)
Vue.config.productionTip = false

Vue.use(vueSwiper)
Vue.use(preventReClick)
Vue.use(loadmore)

Vue.prototype.$message = $message;
Vue.prototype.$hashMap = hashMap;
window._$version = process.env.version;
// Vue.prototype.$x2js = new x2js()

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
