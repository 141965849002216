<template>
  <div id="app" style="height: 100%" @click="isTimeOut">
    <!-- 所以路由转发的入口 -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      lastTime: null, // 最后一次点击的时间
      currentTime: null, // 当前点击的时间
      timeOut: 30 * 60 * 1000 // 设置超时时间:30min 
    }
  },
  // mounted () {
  //   window.addEventListener('beforeunload', () => {
  //     window.sessionStorage.setItem('RouterPath', this.$route.path)
  //     window.sessionStorage.setItem('RouterPathVlaue', this.$route.meta.RouterValue)
  //   })
  //   window.addEventListener('load', () => {
  //     this.$router.push(window.sessionStorage.getItem('RouterPath'))
  //   })
  // *****************
  //   this.screenWidth = document.body.clientWidth
  //   this.screenHeight = document.body.clientHeight
  //   console.log(this.screenWidth, this.screenHeight)
  //   window.onresize = () => {
  //     return (() => {
  //       this.screenWidth = document.body.clientWidth
  //       this.screenHeight = document.body.clientHeight
  //     })()
  //   }

  // },
  // destroyed () {
  //   window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  // }
  created () {
    this.lastTime = new Date().getTime()
  },
  methods: {
    isTimeOut () {
      this.currentTime = new Date().getTime() // 记录这次点击的时间
      if (this.currentTime - this.lastTime > this.timeOut) {
        // 判断上次最后一次点击的时间和这次点击的时间间隔是否大于1s分钟
        if (window.sessionStorage.getItem('username') === null) {
          this.lastTime = new Date().getTime()
        } else {
          // this.$alert('登录超时，请重新登录', '提示信息', {
          //   confirmButtonText: '确定'
          // })
          window.sessionStorage.clear()
          this.$router.push('/login')
        }
      } else {
        this.lastTime = new Date().getTime() // 如果在1s内点击，则把这次点击的时间记录覆盖掉之前存的最后一次点击的时间
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
