// cloneDeep函数
export function cloneDeep(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  let result = Array.isArray(obj) ? [] : {};
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] =
        typeof obj[key] === "object" ? cloneDeep(obj[key]) : obj[key];
    }
  }
  return result;
}

// 防抖函数
export const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply(this, arguments);
    }, delay);
  };
};

// 节流函数
export const throttle = (fn, delay) => {
  let flag = true;
  return function () {
    if (!flag) return;
    flag = false;
    setTimeout(() => {
      fn.apply(this, arguments);
      flag = true;
    }, delay);
  };
};

// 返回list 对应的label 或者 value
export const hashMap = (arr, key, defaultReturn) => {
  let map = {};
  arr.forEach((item) => {
    map[item.value] = item.label;
    map[item.label] = item.value;
  });
  return map[key] === undefined ? defaultReturn : map[key];
};

// 校验身份证号
export function validateIdNumber(idNum) {
  // 将身份证号转换为字符串
  idNum = String(idNum);
  // 正则表达式判断格式是否正确
  var regExp = /^(\d{15}$|^\d{18}$|^\d{17}(\d|X|x))$/;
  if (!regExp.test(idNum)) {
    return false;
  }
  // 判断出生日期是否正确
  var year, month, day, birthday;
  if (idNum.length === 15) {
    year = "19" + idNum.substring(6, 8);
    month = idNum.substring(8, 10);
    day = idNum.substring(10, 12);
    birthday = new Date(year + "/" + month + "/" + day);
  } else {
    year = idNum.substring(6, 10);
    month = idNum.substring(10, 12);
    day = idNum.substring(12, 14);
    birthday = new Date(year + "/" + month + "/" + day);
  }
  if (
    birthday.getFullYear() !== parseInt(year) ||
    birthday.getMonth() + 1 !== parseInt(month) ||
    birthday.getDate() !== parseInt(day)
  ) {
    return false;
  }
  // 判断校验位是否正确
  if (idNum.length === 18) {
    var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
    var parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
    var sum = 0;
    for (var i = 0; i < 17; i++) {
      sum += parseInt(idNum[i]) * factor[i];
    }
    if (parity[sum % 11].toString() !== idNum[17].toUpperCase()) {
      return false;
    }
  }
  // 验证通过
  return true;
}
