import { Message } from 'element-ui';

export const $message = options => {
  const message = Message({
    ...options,
    offset: 160
  });
  message.closeALL = Message.closeALL;
  return message
};

//重写一遍success的方法,将offset写入options
['success', 'warning', 'info', 'error'].forEach(type => {
  $message[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options,
        offset: 160
      };
    }
    options.type = type;
    return Message(options);
  };
});

export default $message