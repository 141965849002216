import Router from 'vue-router'

// 目的是为了避免路由重复报错
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
  // mode: 'hash',
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      component: () => import('@/components/Login/login.vue')
    },
    {
      path: '/home',
      component: () => import('@/components/Home/home.vue'),
      redirect: '/approvalmodel',
      children: [
        // 审批管理
        {
          path: '/approvalmodel',
          component: () => import('@/components/Home/cloudmarket/approvalmanager/approvalModel.vue')
        },
        {
          path: '/approvalmodelSet',
          component: () => import('@/components/Home/cloudmarket/approvalmanager/approvalModelSet.vue')
        },

        // 商品管理

        {
          path: '/productApprove',
          component: () => import('@/components/Home/cloudmarket/productmanager/productApprove.vue')
        },
        {
          path: '/classifyManage',
          component: () => import('@/components/Home/cloudmarket/productmanager/classifyManage.vue')
        },
        {
          path: '/productList',
          component: () => import('@/components/Home/cloudmarket/productmanager/productList.vue')
        },
        {
          path: '/productContact',
          component: () => import('@/components/Home/cloudmarket/productmanager/productContact.vue')
        },
        {
          path: '/productStatistics',
          component: () => import('@/components/Home/cloudmarket/productmanager/productStatistics.vue')
        },

        // 新闻中心
        {
          path: '/newList',
          component: () => import('@/components/Home/News/newList.vue')
        },
        {
          path: '/addNew',
          component: () => import('@/components/Home/News/addNew.vue')
        },

        // 合作伙伴管理
        {
          path: '/partnerApprove',
          component: () => import('@/components/Home/cloudmarket/partnermanager/partnerApprove.vue')
        },
        {
          path: '/partnerRegister',
          component: () => import('@/components/Home/cloudmarket/partnermanager/partnerRegister.vue')
        },
        {
          path: '/partnerManage',
          component: () => import('@/components/Home/cloudmarket/partnermanager/partnerManage.vue')
        },
        {
          path: '/levelManage',
          component: () => import('@/components/Home/cloudmarket/partnermanager/levelManage.vue')
        },

        // 订单管理
        {
          path: '/orderManage',
          component: () => import('@/components/Home/cloudmarket/ordermanager/orderManage.vue')
        },
        {
          path: '/priceSet',
          component: () => import('@/components/Home/cloudmarket/ordermanager/priceSet.vue')
        },
        {
          path: '/WorksheetManage',
          component: () => import('@/components/Home/cloudmarket/ordermanager/WorksheetManage.vue')
        },
        {
          path: '/PayDetail',
          component: () => import('@/components/Home/cloudmarket/ordermanager/PayDetail.vue')
        },
        {
          path: '/PayCardRecord',
          component: () => import('@/components/Home/cloudmarket/ordermanager/PayCardRecord.vue')
        },

        {
          path: '/orderset',
          component: () => import('@/components/Home/cloudmarket/ordermanager/orderSet.vue')
        },
        {
          path: '/orderapprove',
          component: () => import('@/components/Home/cloudmarket/ordermanager/orderApprove.vue')
        },

        // 系统管理模块
        {
          path: '/user',
          component: () => import('@/components/Home/systemanager/user.vue')
        },
        {
          path: '/role',
          component: () => import('@/components/Home/systemanager/role.vue')
        },
        {
          path: '/organize',
          component: () => import('@/components/Home/systemanager/organize.vue')
        },
        {
          path: '/privilege',
          component: () => import('@/components/Home/systemanager/privilege.vue')
        },
        {
          path: '/system_resource',
          component: () => import('@/components/Home/systemanager/system_resource.vue')
        },
        // 日志管理模块
        {
          path: '/userLog',
          component: () => import('@/components/Home/logmanager/userLog.vue')
        },
        {
          path: '/userVIPLog',
          component: () => import('@/components/Home/logmanager/userVIPLog.vue')
        },


        // 产品管理
        {
          path: '/toolManage',
          component: () => import('@/components/Home/cloudmarket/toolmanager/toolmanager.vue')
        },
        {
          path: '/versionManage',
          component: () => import('@/components/Home/cloudmarket/toolmanager/versionmanager.vue')
        },
        {
          path: '/IDEtoolManage',
          component: () => import('@/components/Home/cloudmarket/toolmanager/IDEtoolManage.vue')
        },

        // 评论管理
        {
          path: '/searchRecord',
          component: () => import('@/components/Home/commentmanager/searchRecord.vue')
        },
        {
          path: '/usercomment',
          component: () => import('@/components/Home/commentmanager/usercomment.vue')
        },
        {
          path: '/blackmanager',
          component: () => import('@/components/Home/commentmanager/blackLists.vue')
        },

        // 用户管理
        {
          path: '/userManage',
          component: () => import('@/components/Home/usermanager/user.vue')
        },

        // 学院管理
        {
          path: '/videoManage',
          component: () => import('@/components/Home/schoolmanager/videoSet.vue')
        },
        {
          path: '/helpManage',
          component: () => import('@/components/Home/schoolmanager/helpS.vue')
        },

        // 产品营销
        {
          path: '/actionSet',
          component: () => import('@/components/Home/salemanager/actionSet.vue')
        },
        {
          path: '/saleManage',
          component: () => import('@/components/Home/salemanager/saleManage.vue')
        },
        {
          path: '/saleData',
          component: () => import('@/components/Home/salemanager/saleData.vue')
        },
      ]
    },


  ]
})

// 挂载路由导航守卫,路由更新时就保存
router.beforeEach((to, from, next) => {
  if (to.path === '/login') return next()
  const tokenstr = window.sessionStorage.getItem('token')
  if (!tokenstr) return next('/login')
  next()
})

export default router
